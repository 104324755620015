@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    text-decoration: none;
}

.dark-mode {
    background-color: #141414;
}

.light-mode {
    background-color: #ffffff;
    color: #000;

    .next-error-h1 {
        border-right: 1px solid #000;
    }
}

.evalautionContainer {
    margin-bottom: 200px;
}

.mdxSlider img {
    width: 100%;
}

.testset-column {
    cursor: pointer;
}

.testset-column a {
    color: inherit;
}

.editor-input > p {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        box-shadow: 0 0 0 7px rgba(255, 82, 82, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.hover-button-wrapper {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.ant-table-row:hover .hover-button-wrapper {
    opacity: 1;
}

.ant-tooltip-inner {
    max-height: 300px;
    overflow-y: auto;
}

@media screen and (max-width: 1572px) {
    .evalaution-title {
        min-height: 80px;
    }
}

@media screen and (max-width: 1024px) {
    .evalaution-title {
        min-height: 100px;
    }
}

@media screen and (max-width: 848px) {
    .evalaution-title {
        min-height: 120px;
    }
}

/* It removes antd tabs transition to fix lag when using type="editable-card" */
.editable-card.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    transition: none;
}

/* It aligns the settings icon in the table column  */
.ant-table-cell-fix-right-first svg {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

/* Custom loading circle for app creation proccess  */
.loading-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
}
.loading-circle path {
    stroke-width: 2;
    animation: colorChange 1.6s infinite;
    transition:
        stroke 0.4s ease,
        stroke-width 0.4s ease;
    transition-duration: 0.5s;
}

.loading-circle path:nth-child(1) {
    animation-delay: 0s;
}
.loading-circle path:nth-child(2) {
    animation-delay: 0.4s;
}
.loading-circle path:nth-child(3) {
    animation-delay: 0.8s;
}
.loading-circle path:nth-child(4) {
    animation-delay: 1.2s;
}
@keyframes colorChange {
    0%,
    25% {
        stroke: #36cfc9;
    }
    26%,
    100% {
        stroke: #d6dee6;
    }
}
